@import "./fonts/fonts.css";

body {
  margin: 0;
  font-family: "Proxima Nova", "Source Sans Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
  overflow: visible !important;
  box-sizing: border-box !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  font-family: "Klavika";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}

.column_title {
  font-family: "Klavika";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.text {
  font-family: "Proxima Nova", "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-size: 18px;
}
